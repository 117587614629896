export default {
  data() {
    return {
      categoryNameEn: "",
      categoryNameEs: "",
      categoryType: "",
      itemsCategoryType: [],
      screenWidth: 0,
      texts: "",
      images: [],
      allImages: [],
      iTotalImages: 0,
      sCategoryId: "",
      bLoading: false,
      color: "#ffffff",
      bSkeleton: false,
    };
  },
  beforeMount() {
    this.texts = FILE.categoryTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  upload() {
    this.$refs.clearUpload.clear();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.$store.commit("setDialogEditCategory", {
      active: false,
      arr: [],
    });
    this.categoryNameEn = "";
    this.categoryNameEs = "";
    this.images = [];
    (this.color = "#ffffff"), (this.allImages = []);
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    setImages(e) {
      this.images = e;
      this.iTotalImages = this.images.length + this.allImages.length;
    },
    editCategory() {
      const changeImage = this.images.length == 1;
      const emptyImage = this.images.length == 0 && this.allImages == "";

      this.bLoading = true;

      const params = {
        oCategories: {
          sCategoryTypeId: this.categoryType,
          sImageKey: "",
          aTranslations: [
            {
              sName: this.categoryNameEn,
              sLang: "en",
            },
            {
              sName: this.categoryNameEs,
              sLang: "sp",
            },
          ],
          sColorCode: this.color,
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      if (!emptyImage) {
        DB.put(
          `${URI}/api/v1/${this.selectLanguage}/categories/${this.sCategoryId}`,
          params,
          config
        )
          .then((response) => {
            if (changeImage) {
              this.sendImage(response);
            } else {
              this.bLoading = false;
              this.images = [];
              this.emitClose();
              this.mixSuccess(response.data.message);
              this.$store.commit("refresher", true);
            }
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.bLoading = false;
        this.mixError(this.texts.upload.message);
      }
    },
    sendImage(resp) {
      var form = new FormData();
      form.append("image", this.images[0]);
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/categories/${resp.data.results.sCategoryId}`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.emitClose();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
          this.images = [];
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
    emitClose() {
      this.$store.commit("setDialogEditCategory", {
        active: false,
        arr: [],
      });
      this.categoryNameEn = "";
      this.categoryNameEs = "";
      this.images = [];
      (this.color = "#ffffff"), (this.allImages = []);
      // this.$refs.clearUpload.clear();
    },
    getCategoryType() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/types/`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.itemsCategoryType = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getCategoryData() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/categories/${this.sCategoryId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.color = response.data.results.sColorCode;
          this.allImages = response.data.results.oImages;
          this.iTotalImages = 1;

          this.categoryNameEn = response.data.results.sTranslations.find(
            (res) => {
              return res.sLang == "en";
            }
          ).sName;
          this.categoryNameEs = response.data.results.sTranslations.find(
            (res) => {
              return res.sLang == "sp";
            }
          ).sName;
          this.bSkeleton = true;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogEditCategory() {
      return this.$store.state.dialogEditCategory;
    },
    validateForm() {
      return (
        this.categoryNameEn !== "" &&
        this.categoryNameEs !== "" &&
        this.categoryType !== "" &&
        this.color !== "" &&
        this.categoryNameEn !== null &&
        this.categoryNameEs !== null &&
        this.categoryType !== null &&
        this.color !== null
      );
    },
  },
  watch: {
    dialogEditCategory: function() {
      if (this.dialogEditCategory) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.texts = FILE.categoryTexts[this.selectLanguage];
        this.sCategoryId = this.$store.state.arrEditCategory.sCategoryId;
        this.categoryType = this.$store.state.arrEditCategory.sCategoryTypeId;
        this.getCategoryData();
        this.getCategoryType();
      }
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.categoryTexts[this.selectLanguage];
      }
    },
    color: function() {
      setTimeout(() => {
        document.getElementsByClassName(
          "icp__input"
        )[0].style.backgroundColor = this.color;
      }, 500);
    },
    iTotalImages: function() {
      setTimeout(() => {
        document.getElementsByClassName(
          "icp__input"
        )[0].style.backgroundColor = this.color;
      }, 5);
    },
  },
};
