export default {
  data() {
    return {
      email: "",
      password: "",
      bShowPassword: false,
      loadingForm: false,
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.loginTexts[this.selectLanguage];
  },
  methods: {
    rSofex() {
      window.open('https://sofex.com.mx/', '_blank');
    },
    keyPressEnter(e){
      var keycode = (e.keyCode ? e.keyCode : e.which);
      if (keycode == '13') {
        this.login();
          e.preventDefault();
          return false;
      }
    },
    login() {
      this.loadingForm = true;

      const payload = {
        sEmail: this.email,
        sPassword: this.password,
      };
      //post credentials and get access token from laravel backend
      DB.post(`${URI}/api/v1/${this.selectLanguage}/auth/administrator/`, payload)
        .then((response) => {
          this.loadingForm = false;
          //we store the access token in localstorage, so that we can use it again.
          this.$store.commit("setToken", response.data.sToken);
          //we store the email in localstorage, so that we can use it again.
          this.$store.commit("setEmail", response.data.sEmail);
          //we store the full name in localstorage, so that we can use it again./
          this.$store.commit("setFullName", response.data.sFullname);
          //we store the id in localstorage, so that we can use it again./
          this.$store.commit("setAdministratorId", response.data.sUserId);
          //we store the permissions in localstorage, so that we can use it again./
          this.$store.commit("setPermissions",response.data.aPermissions);


   
          //after storing token, send user to home page.
          this.mixInfo(response.data.sMessage);
          // this.$router.push("/admin/orders");
          // this.$router.push('/admin')
          this.$router.go()
        })
        .catch((error) => {
          this.loadingForm = false;
          this.mixError(error.response.data.message,error.response.status);
        });
    },
  },
  computed: {
    validateForm() {
      return (
        this.email !== "" &&
        this.password !== "" &&
        this.email !== null &&
        this.password !== null
      );
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.loginTexts[this.selectLanguage];
      }
    },
  },
};