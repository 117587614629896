import texts from "../../../../texts/commissions";

export default {
  name: "OrdersComponent",
  data() {
    return {
      allDateTable: [],
      heightTable: 0,
      bHeightTable: 0,
      tableData: [],
      dataTableExchangeRates: [],
      dataTableInOut: [],
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      iTotalItems: 0,
      screenHeight: 0,
      screenWidth: 0,
      texts: "",
      tab: null,
    };
  },
  beforeMount() {
    this.$store.commit("setMenuSidebar", {active:false});
    this.texts = FILE.commissionsTexts[this.selectLanguage];
  },
  mounted() {
    this.fillTableChangeRates();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // consulta el detalle de la comision
    detailItem(item) {
      this.$store.commit("setdialogDetailsCommissionsAddEdit", {
        active: true,
        arr: item,
      });
    },
    // consulta el tipo de cambio
    fillTableChangeRates() {
      this.allDateTable = []
      DB.get(`${URI}/api/v1/${this.selectLanguage}/exchange-rates/current`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.dataTableExchangeRates = response.data.results
          this.dataTableExchangeRates["sNameGlobal"] = this.texts.exChangeRate
          this.dataTableExchangeRates["sValueGlobal"] = this.dataTableExchangeRates.dRatePerUSD
          this.dataTableExchangeRates["bType"] = 1; //EXCHANGE RATES
          this.fillTableInOut();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // consulta el In&Out
    fillTableInOut(data) {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/in-out-rates/current`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.dataTableInOut = response.data.results
          this.dataTableInOut["sNameGlobal"] = 'In&Out'
          this.dataTableInOut["sValueGlobal"] = this.dataTableInOut.dRate
          this.dataTableInOut["bType"] = 2; //IN&OUT
          this.fillTable();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // consulta las comisiones
    fillTable(data) {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/fees`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          let oExchangeRates = this.dataTableExchangeRates
          let oInOut = this.dataTableInOut;
          let aComissions = response.data.results
          .map((e)=>{
            return{
              ...e,
              sNameGlobal: e.sFeeName,
              sValueGlobal: e.dValue
            }
          });
          
          this.allDateTable.push(oExchangeRates);
          this.allDateTable.push(oInOut);

          aComissions.map((e) => {
            this.allDateTable.push(e);
          });
          this.allDateTable = this.allDateTable.map((e) => {
            return {
              ...e,
              AllbType: e.bType ? e.bType : 0,
              AllsName: e.sNameGlobal,
              AllCommissionsType: e.sValueGlobal,
              // AllCommissionsType: e.dRate
              //   ? "$ " + e.dRate
              //   : e.dRatePerUSD
              //   ? "$ " + e.dRatePerUSD + " " + this.texts.forUSD
              //   : e.sFeeTypeName == "Amount"
              //   ? "$ " + e.dValue
              //   : "% " + e.dValue,
              AllRatePercentage: e.sFeeTypeName ? e.sFeeTypeName : "",
              AllDate: e.sDate ? e.sDate : e.tCreatedAtView,
            };
          });
          this.tableData = response.data.results.map((e) => {
            return {
              ...e,
              bType: 0, //FEES
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal + 2;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
      if (window.innerWidth > 900) {
        this.screenWidth = window.innerWidth - 140;
      } else {
        this.screenWidth = window.innerWidth - 40;
      }
    },
  },
  computed: {
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },

    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function() {
      this.iCurrentPage = 1;
      this.fillTableChangeRates();
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTableChangeRates();
    },
    iCurrentPage: function() {
      this.fillTableChangeRates();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.commissionsTexts[this.selectLanguage];
        this.fillTableChangeRates();
      }
    },
  },
};
