import Vue from "vue";
import Vuex from "vuex";
import PersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // --- close and open menu --- //
    bMenuSidebar: false,

    // --- user data --- //
    sToken: "",
    sAdministratorId: "",
    sFullName: "",
    sEmail: "",
    permissions: null || localStorage.getItem("user-permissions"),

    // --- table data --- //
    refresh: false,
    sSearch: "",
    dStartDate: null,
    dEndDate: null,
    iCurrentPage: 1,
    iPageNumber: 1,
    iItemsPerPage: 10,
    iNumPages: 0,
    iTotalItems: 0,
    sCategoryId: "",
    sSubcategoryId: "",

    // --- global dialog item data --- //
    titleGlobalDialog: "",
    descripcionGlobalDialog: "",
    noteGlobalDialog: "",
    chkLabelGlobalDialog: "",
    apiGlobalDialog: "",
    dialogGlobalDialog: false,

    // --- delete item data --- //
    titleDelete: "",
    descripcionDelete: "",
    noteDelete: "",
    chkLabelDelete: "",
    apiDelete: "",
    dialogDelete: false,

    // --- password change item data --- //
    titlePasswordChange: "",
    descripcionPasswordChange: "",
    apiPasswordChange: "",
    dialogPasswordChange: false,

    // --- user detail dialog global item data --- //
    dialogGlobalDetailsUser: false,
    arrDetailGlobalUser: [],
    apiGlobalUser: "",
    apiGlobalUserChangeStatus: "",
    returnMain: false,
    dobleConfirm: false,
    params: {},
    sAction: "",

    // --- warehouse detail dialog global item data --- //
    dialogGlobalDetailWarehouse: false,
    arrDetailGlobalWarehouse: [],
    apiGlobalWarehouseDetail: "",
    apiGlobalDocumentDetail: "",

    // --- Language --- //
    language: localStorage.getItem("language") || "en",

    // --- Dialogs --- //
    dialogAddCategory: false,
    dialogEditCategory: false,
    dialogAddSubcategory: false,
    dialogEditSubcategory: false,
    dialogAddArticle: false,
    dialogEditArticle: false,
    dialogFilterArticle: false,
    dialogAddSubadmin: false,
    dialogAddPermits: false,
    dialogEditSubadmin: false,
    dialogDetailSubadmin: false,
    dialogEditCustomer: false,
    dialogDetailsCustomerWareHouse: false,
    dialogEditVendor: false,
    dialogDetailArticle: false,
    dialogDetailsCustomerUser: false,
    dialogDetailsCommissionsAddEdit: false,
    dialogUpdateStatusActive: false,
    dialogUpdateStatusClaims: false,
    DialogAddWarehouse: false,
    dialogAddDocument: false,
    dialogPayMethod: false,
    dialogDateArrival: false,
    dialogEditFilesActive: false,
    dialogAddProductsActive: false,
    dialogChangePaymentMethodActive: false,

    // --- Arrays data --- //
    arrEditCategory: [],
    arrEditSubcategory: [],
    arrEditArticle: [],
    arrEditSubadmin: [],
    arrDetailSubadmin: [],
    arrEditCustomer: [],
    arrEditVendor: [],
    arrDetailArticle: [],
    arrDetailCustomerWareHouse: [],
    arrDetailCustomerUser: [],
    arrDetailComissionsAddEdit: [],
    dialogUpdateStatusArr: [],
    dialogUpdateStatusArrClaims: [],
    dialogEditFilesArr: [],
    dialogAddProductsArr: [],
    // dialogChangePaymentMethodArr: [],

    dialogDateArrivaltArrivalAtBorderWarehouse: "",

    // name to customer or vendors selected
    sNameDetailSelected: "",

    //tab global
    iTab: null,

    //Google maps
    markers: [],
    place: [],

    //Filter
    sFilter: 0,
    aOrderStatus: [],
    bPaymentStatus: null,
    aClaimStatus: [],
    bUserState: null,
    bRangeStatus: null,
    bAlphabeticallyStatus: null,
    bPriceStatus: null,

    sManagers: "",

    dialogKillSession: false,

    // --- dialog gallery --- //
    galleryActive: false,
    galleryImageSelected: 0,
    gallerAllImages: [],
  },
  mutations: {
    setTab(state, value) {
      state.iTab = value;
    },
    setNameDetailSelected(state, value) {
      state.sNameDetailSelected = value;
    },

    // --- close and open menu --- //
    setMenuSidebar(state, value) {
      state.bMenuSidebar = value.active;
      state.iTab = 0;
    },

    // --- changing language --- //
    toggle_language(state, value) {
      state.language = value;
      localStorage.setItem("language", value);
    },

    // --- user data --- //
    setToken(state, e) {
      state.sToken = e;
    },
    setFullName(state, e) {
      state.sFullName = e;
    },
    setPermissions(state, e) {
      state.permissions = e;
      localStorage.setItem("user-permissions", JSON.stringify(e));
    },
    setAdministratorId(state, e) {
      state.sAdministratorId = e;
    },
    setEmail(state, e) {
      state.sEmail = e;
    },

    // --- upadte tables --- //
    setsCategoryId(state, item) {
      state.sCategoryId = item;
    },
    setsSubCategoryId(state, item) {
      state.sSubcategoryId = item;
    },
    refresher(state, item) {
      state.refresh = item;
    },
    setSearch(state, item) {
      state.sSearch = item;
    },
    setStartDate(state, item) {
      state.dStartDate = item;
    },
    setEndDate(state, item) {
      state.dEndDate = item;
    },
    setINumPages(state, item) {
      state.iNumPages = item;
    },
    setIPageNumber(state, item) {
      state.iPageNumber = item;
    },
    setITotal(state, item) {
      state.iTotalItems = item;
    },
    setiItemsPerPage(state, item) {
      state.iItemsPerPage = item;
    },
    setICurrentPage(state, item) {
      state.iCurrentPage = item;
    },
    setPrevious(state) {
      if (state.iPageNumber > 1) {
        state.iPageNumber--;
      }
    },
    setNext(state) {
      if (state.iPageNumber < state.iNumPages) {
        state.iPageNumber++;
      }
    },

    // --- dialogs --- //
    setGlobalDialog(state, value) {
      state.titleGlobalDialog = value.title;
      state.descripcionGlobalDialog = value.descipcion;
      state.apiGlobalDialog = value.api;
      state.dialogGlobalDialog = value.active;
      state.returnMain = value.return;
      state.dobleConfirm = value.dobleConfirm;
      (state.noteGlobalDialog = value.note),
        (state.chkLabelGlobalDialog = value.chkLabel),
        (state.params = value.params);
    },
    setDelete(state, value) {
      state.titleDelete = value.title;
      state.descripcionDelete = value.descipcion;
      state.apiDelete = value.api;
      state.dialogDelete = value.active;
      state.returnMain = value.return;
      state.dobleConfirm = value.dobleConfirm;
      (state.noteDelete = value.note), (state.chkLabelDelete = value.chkLabel);
    },
    setPasswordChange(state, value) {
      state.apiPasswordChange = value.api;
      state.dialogPasswordChange = value.active;
    },
    setDialogAddCategory(state, value) {
      state.dialogAddCategory = value;
    },
    setDialogEditCategory(state, value) {
      state.dialogEditCategory = value.active;
      state.arrEditCategory = value.arr;
    },
    setDialogAddSubcategory(state, value) {
      state.dialogAddSubcategory = value;
    },
    setDialogEditSubcategory(state, value) {
      state.dialogEditSubcategory = value.active;
      state.arrEditSubcategory = value.arr;
    },
    setDialogAddArticle(state, value) {
      state.dialogAddArticle = value;
    },
    setDialogEditArticle(state, value) {
      state.dialogEditArticle = value.active;
      state.arrEditArticle = value.arr;
    },
    setDialogFilterArticle(state, value) {
      state.dialogFilterArticle = value;
    },
    setDialogAddSubadmin(state, value) {
      state.dialogAddSubadmin = value;
    },
    setDialogAddPermits(state, value) {
      state.dialogAddPermits = value;
    },
    setDialogEditSubadmin(state, value) {
      state.dialogEditSubadmin = value.active;
      state.arrEditSubadmin = value.arr;
    },
    setDialogDetailSubadmin(state, value) {
      state.dialogDetailSubadmin = value.active;
      state.arrDetailSubadmin = value.arr;
    },
    setDialogEditCustomer(state, value) {
      state.dialogEditCustomer = value.active;
      state.arrEditCustomer = value.arr;
    },
    setDialogDetailsCustomerWareHouse(state, value) {
      state.dialogDetailsCustomerWareHouse = value.active;
      state.arrDetailCustomerWareHouse = value.arr;
    },
    setDialogEditVendor(state, value) {
      state.dialogEditVendor = value.active;
      state.arrEditVendor = value.arr;
    },
    setDialogDetailArticle(state, value) {
      state.dialogDetailArticle = value.active;
      state.arrDetailArticle = value.arr;
    },
    setdialogDetailsCustomerUser(state, value) {
      state.dialogDetailsCustomerUser = value.active;
      state.arrDetailCustomerUser = value.arr;
    },
    setdialogGlobalWarehouse(state, value) {
      state.dialogGlobalDetailWarehouse = value.active;
      state.arrDetailGlobalWarehouse = value.arr;
      (state.apiGlobalWarehouseDetail = value.apidetail),
        (state.apiGlobalDocumentDetail = value.apidocument);
    },
    setdialogDetailsGlobalUser(state, value) {
      state.dialogGlobalDetailsUser = value.active;
      state.arrDetailGlobalUser = value.arr;
      state.apiGlobalUser = value.api;
      state.apiGlobalUserChangeStatus = value.apiChangeStatus;
    },
    setdialogDetailsCommissionsAddEdit(state, value) {
      state.dialogDetailsCommissionsAddEdit = value.active;
      state.arrDetailComissionsAddEdit = value.arr;
    },
    setDialogUpdateStatus(state, value) {
      state.dialogUpdateStatusActive = value.active;
      state.dialogUpdateStatusArr = value.arr;
    },
    setDialogEditFiles(state, value) {
      state.dialogEditFilesActive = value.active;
      state.dialogEditFilesArr = value.arr;
    },
    setDialogAddProducts(state, value) {
      state.dialogAddProductsActive = value.active;
      state.dialogAddProductsArr = value.arr;
    },
    setDialogUpdateStatusClaims(state, value) {
      state.dialogUpdateStatusClaims = value.active;
      state.dialogUpdateStatusArrClaims = value.arr;
    },
    setDialogAddWarehouse(state, value) {
      state.DialogAddWarehouse = value.active;
    },
    setDialogAddDocument(state, value) {
      state.dialogAddDocument = value.active;
    },
    setUbicationMap(state, value) {
      state.markers = value.markers;
      state.place = value.place;
    },
    setFilter(state, value) {
      state.sFilter = value.sFilter;
    },
    setOrderStatus(state, value) {
      state.aOrderStatus = value;
    },
    setClaimStatus(state, value) {
      state.aClaimStatus = value;
    },
    setRangeStatus(state, value) {
      state.bRangeStatus = value;
    },
    setAlphabeticallyStatus(state, value) {
      state.bAlphabeticallyStatus = value;
    },
    setPriceStatus(state, value) {
      state.bPriceStatus = value;
    },
    setPaymentStatus(state, value) {
      state.bPaymentStatus = value;
    },
    setUserState(state, value) {
      state.bUserState = value;
    },
    setManagers(state, value) {
      state.sManagers = value;
    },
    setDialogKillSession(state, value) {
      state.dialogKillSession = value;
    },
    setDialogPayMethod(state, value) {
      state.dialogPayMethod = value;
    },
    setDialogChangePaymentMethod(state, value) {
      state.dialogChangePaymentMethodActive = value;
    },
    setDialogDateArrival(state, value) {
      state.dialogDateArrival = value.active;
      state.dialogDateArrivaltArrivalAtBorderWarehouse = value.sDate;
    },
    setGallery(state, value) {
      state.galleryActive = value.active;
      state.galleryImageSelected = value.imageSelected;
      state.gallerAllImages = value.imagesArr;
    },
    setGalleryImageSelected(state, value) {
      state.galleryImageSelected = value;
    },
  },
  actions: {
    logout() {
      this.reset();
    },
  },
  modules: {},
  plugins: [PersistedState()],
});
