export default {
  name: "Articles",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      screenWidth: 0,
      screenHeight: 0,
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.searchbarTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("load", this.contentTableHeight)
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    contentTableHeight() {
      this.heightTable = (document.getElementsByClassName('tableContent'))
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable = (this.heightTable[0].clientHeight > 760 || window.innerHeight < 800)
      }
    },
    handleResize() {
      this.contentTableHeight()
      this.screenHeight = window.innerHeight - 70;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth
      }
    },
  },
  computed: {
    bMenuSidebar() {
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
      }
    },
  },
};